import { gql } from '@apollo/client/core';

export const GET_LOCALITIES = gql`
  query GetLocalities2($postcode: String!, $suburb: String!) {
    getLocalities(postcode: $postcode, suburb: $suburb) {
      id
      name
      postcode {
        id
        postcode
      }
      state {
        id
        name
      }
    }
  }
`;

export const GET_DISTRIBUTORS_COUNT_BY_LOCALITY = gql`
  query GetLocality($localityId: ID!) {
    getLocality(localityId: $localityId) {
      id
      name
      distributorsCount
      distributors {
        id
        name
        supplyarea {
          id
          name
        }
      }
      postcode {
        id
        climateZone {
          climateZone
        }
        postcode
      }
      state {
        id
        name
      }
    }
  }
`;

export const GET_RETAILERS = gql`
  query GetRetailersBySupplyarea(
    $supplyareaId: ID!
    $planFilters: PlanFiltersInput!
  ) {
    getRetailersBySupplyarea(
      supplyareaId: $supplyareaId
      planFilters: $planFilters
    ) {
      id
      orgName
      retailerCode
    }
  }
`;

export const GET_PLANS_BY_RETAILERS_SUPPLYAREA = gql`
  query GetPlans(
    $retailerId: ID!
    $supplyareaId: ID!
    $planFilters: PlanFiltersInput!
  ) {
    getPlansByRetailerSupplyarea(
      retailerId: $retailerId
      supplyareaId: $supplyareaId
      planFilters: $planFilters
    ) {
      id
      name
      planId
    }
  }
`;

export const GET_RECOMMENDATION = gql`
  query GetRecommendation(
    $propertyId: ID!
    $planId: ID
    $planFilters: PlanFiltersInput!
    $preferencesFilters: PreferencesFiltersInput
    $offset: Int
    $limit: Int
    $region: String
    $keywordUri: String
  ) {
    resultSack(
      propertyId: $propertyId
      planId: $planId
      planFilters: $planFilters
      preferencesFilters: $preferencesFilters
      offset: $offset
      limit: $limit
      keywordUri: $keywordUri
      region: $region
    ) {
      adBanner {
        thirdPartyBanner {
        	id
          imageDesktop
          imageMobile
          hoverText
          link
        }
        nativeBanner {
        	id
          svgIcon
          title
          description
          buttonText
          buttonLink
          extraButtonText
          extraButtonLink
        }
      }
      availablePlansSize
      profilePlansSize
      moreResults
      recommendationList {
        annualBaseCost
        annualFeeTotal
        annualSolarExport
        annualControlledLoad
        annualGreenPowerChargesPct100
        annualValueDiscount
        annualValueGuaranteedDiscount
        annualPercentageDiscount
        annualPercentageGuaranteedDiscount
        annualDspCost
        annualUsageCost
        finalCost
        incentives {
          id
        }
        restrictions {
          id
          description
          restrictionType
        }
        paymentOptions {
          id
        }
        plan {
          id
          blockplan {
            id
          }
          touplan {
            id
          }
          srplan {
            id
          }
          retailer {
            id
            orgName
            websiteUrl
            referralUrl
            vecResiEfsUrl
            vecBusiEfsUrl
            # Filter filed
            ausBasedCustomerService
            ausOwned
          }
          planId
          name
          tariffType
          contractTermType

          # Filter fields
          isFixed
          evOnly
          discounts {
            discountType
            description
          }
          greencharges {
            greenChargeOptions {
              percentGreenPower
            }
          }
          fees {
            feeType
          }
        }
      }
      isUserSelectedPlan
      actualPlan {
        annualBaseCost
        annualFeeTotal
        annualSolarExport
        annualControlledLoad
        annualGreenPowerChargesPct100
        annualValueDiscount
        annualValueGuaranteedDiscount
        annualPercentageDiscount
        annualPercentageGuaranteedDiscount
        annualTotalUsage
        annualSolarExport
        annualSolarExportKwh
        annualDspCost
        annualUsageCost
        finalCost
        plan {
          name
          tariffType
          retailer {
            orgName
          }
          distributor {
            name
          }
        }
      }
      estimatedSavings
      medianPlanFinalCost
      tokenData {
        planTypeFilters {
          resOrBus
          solar
          sr
          cl
          br
          tou
          dc
          restrictions
          hasBill
          hasPeak
          ausOwned
        }
        lifeSupport
        postcode
        billType
      }
    }
  }
`;

export const GET_RECOMMENDATION_BY_TOKEN = gql`
  query GetRecommendationByToken(
    $token: String!
    $planFilters: PlanFiltersInput!
    $preferencesFilters: PreferencesFiltersInput
    $offset: Int
    $limit: Int
  ) {
    resultSack: getRecommendationByToken(
      token: $token
      planFilters: $planFilters
      preferencesFilters: $preferencesFilters
      offset: $offset
      limit: $limit
    ) {
      adBanner {
        thirdPartyBanner {
        	id
          imageDesktop
          imageMobile
          hoverText
          link
        }
        nativeBanner {
        	id
          svgIcon
          title
          description
          buttonText
          buttonLink
          extraButtonText
          extraButtonLink
        }
      }
      availablePlansSize
      profilePlansSize
      moreResults
      recommendationList {
        annualBaseCost
        annualFeeTotal
        annualSolarExport
        annualControlledLoad
        annualGreenPowerChargesPct100
        annualValueDiscount
        annualValueGuaranteedDiscount
        annualPercentageDiscount
        annualPercentageGuaranteedDiscount
        annualDspCost
        annualUsageCost
        finalCost
        incentives {
          id
        }
        restrictions {
          id
          description
          restrictionType
        }
        paymentOptions {
          id
        }
        plan {
          id
          blockplan {
            id
          }
          touplan {
            id
          }
          srplan {
            id
          }
          retailer {
            id
            orgName
            websiteUrl
            referralUrl
            vecResiEfsUrl
            vecBusiEfsUrl
            # Filter filed
            ausBasedCustomerService
            ausOwned
          }
          planId
          name
          tariffType
          contractTermType

          # Filter fields
          isFixed
          evOnly
          discounts {
            discountType
            description
          }
          greencharges {
            greenChargeOptions {
              percentGreenPower
            }
          }
          fees {
            feeType
          }
        }
      }
      isUserSelectedPlan
      actualPlan {
        annualBaseCost
        annualFeeTotal
        annualSolarExport
        annualControlledLoad
        annualGreenPowerChargesPct100
        annualValueDiscount
        annualValueGuaranteedDiscount
        annualPercentageDiscount
        annualPercentageGuaranteedDiscount
        annualTotalUsage
        annualSolarExport
        annualSolarExportKwh
        annualDspCost
        annualUsageCost
        finalCost
        plan {
          name
          tariffType
          retailer {
            orgName
            vecResiEfsUrl
            vecBusiEfsUrl
          }
          distributor {
            name
          }
        }
      }
      estimatedSavings
      medianPlanFinalCost
      tokenData {
        planTypeFilters {
          resOrBus
          solar
          sr
          cl
          br
          tou
          dc
          restrictions
          hasBill
          hasPeak
          ausOwned
        }
        lifeSupport
        postcode
        billType
      }

    }
  }
`;

export const GET_PLAN = gql`
  query GetPlan($planId: ID!) {
    getPlan(planId: $planId) {
      id
      dailySupplyCharge
      retailer {
        id
        orgName
        websiteUrl
        referralUrl
        vecResiEfsUrl
        vecBusiEfsUrl
        ausBasedCustomerService
      }
      distributor {
        name
      }
      planId
      benefitPeriod
      coolingOffDays
      effectiveDate
      billFrequencies {
        number
        period
      }
      isFixed
      meterTypes {
        meterType
      }
      name
      tariffType
      contractTermType
      blockplan {
        blockRateItems {
          index
          unitPrice
          volume
        }
      }
      srplan {
        rate
      }
      touplan {
        touBlocks {
          name
          startDate
          endDate
          touBlockDsp
          touBlockItems {
            name
            unitPrice
            touPeriod
            day
            startTime
            endTime
          }
        }
      }
      solarfits {
        description
        rate
        solarfitType
        usageOperator
        usageOperatorPeriod
        operatorBeginValue
        operatorEndValue
      }

      controlledloads {
        name
        description
        rate
        dailyCharge
        blockPeriod
        periods {
          startTime
          endTime
        }
      }
      isFixed
      incentives {
        id
        name
        description
      }
      restrictions {
        id
        description
        restrictionType
      }
      paymentOptions {
        paymentOption
      }
      evOnly
      fees {
        description
        amount
        feeType
        percent
        feeTerm
      }
      discounts {
        name
        description
        percent
        amount
        discountType
        category
        method
      }
      greencharges {
        description
        greenChargeOptions {
          percentGreenPower
          chargeAmount
        }
      }
      unconditionalPrice
      unconditionalPricePercentDiff
      conditionalPrice
      conditionalPricePercentDiff
      guAmount
      resOrBus
      isVec
      planFactSheet
    }
  }
`;

//
// Mutations
//

export const CREATE_RESIDENCE = gql`
  mutation CreateResidence($residenceData: CalculationInput!) {
    createResidence(residenceData: $residenceData) {
      newResidence {
        id
      }
    }
  }
`;
export const CREATE_BUSINESS = gql`
  mutation CreateBusiness($businessData: CalculationInput!) {
    createBusiness(businessData: $businessData) {
      newBusiness {
        id
      }
    }
  }
`;

export const UPLOAD_BILL = gql`
  mutation UploadBill($file: Upload!) {
    uploadBill(file: $file) {
      bill {
        id
      }
      parsedBill {
        startDate
        endDate
        peak
        offPeak
        shoulder
        generalUsage
        clUsage
        solarGeneration
      }
    }
  }
`;

export const CREATE_MEMBER = gql`
  mutation CreateMember(
    $fname: String!
    $lname: String!
    $email: String!
    $propertyId: ID!
    $additionalInfo: String
    $selectedPlanId: ID!
    $selectedRetailerId: ID!
    $planTypeFilters: PlanFiltersInput!
  ) {
    createMember(
      fname: $fname
      lname: $lname
      email: $email
      propertyId: $propertyId
      additionalInfo: $additionalInfo
      selectedPlanId: $selectedPlanId
      selectedRetailerId: $selectedRetailerId
      planTypeFilters: $planTypeFilters
    ) {
      member {
        id
        email
        resultsToken
      }
    }
  }
`;

export const GET_SITEMAP_REGION = gql`
  query SitemapRegion($regionSlug: String!, $keywordUri: String!) {
    getSitemapRegion(regionSlug: $regionSlug, keywordUri: $keywordUri) {
      id
      title
      h1Content
      h2Content
      text1Content
      text2Content
      text3Content
      postalCode
      householdSize
      solar
      supplyArea {
        distributor {
          name
        }
      }
      filterOptions
    }
  }
`;

export const SELECTED_RETAILER = gql`
  mutation SelectedRetailer(
    $memberId: ID!
    $selectedPlanId: ID!
    $selectedRetailerId: ID!
    $cmVisitor: String
    $cmSession: String
  ) {
    selectedRetailer(
      memberId: $memberId
      selectedPlanId: $selectedPlanId
      selectedRetailerId: $selectedRetailerId
      cmVisitor: $cmVisitor
      cmSession: $cmSession
    ) {
      member {
        id
      }
    }
  }
`;

export const SELECTED_AD_BANNER = gql`
  mutation SelectedAdBanner(
    $memberId: ID!
    $selectedAdBannerId: ID!
  ) {
    selectedAdBanner(
      memberId: $memberId
    	selectedAdBannerId: $selectedAdBannerId
    ) {
      member {
        id
      }
    }
  }
`;

