import React, { useEffect } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import SEOLayout from '../components/layouts/SEOLayout';
import LandingHeader from '../components/landing/Header';
import Footer from '../components/landing/Footer';

interface PageItem {
  path: string;
  name: string;
}

interface GroupedPages {
  [category: string]: PageItem[];
}

interface QueryData {
  allSitePage: {
    nodes: Array<{
      path: string;
    }>;
  };
}

const Categories = () => {

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = '/tailwind.css';  // Path to Tailwind CSS file in public folder
        link.id = 'tailwind-css';
    
        if (!document.getElementById('tailwind-css')) {
          document.head.appendChild(link);
        }
    
        return () => {
          const existingLink = document.getElementById('tailwind-css');
          if (existingLink) {
            document.head.removeChild(existingLink);
          }
        };
  }, []);

  const data = useStaticQuery<QueryData>(graphql`
    query {
      allSitePage(filter: { path: { regex: "/^/results\//" } }) {
        nodes {
          path
        }
      }
    }
  `);

  // Group pages by category
  const groupedPages = data.allSitePage.nodes.reduce<GroupedPages>((acc, { path }) => {
    // Skip the main /results/ page
    if (path === '/results/') return acc;
    
    // Remove '/results/' prefix and get all path segments
    const segments = path.replace('/results/', '').split('/');
    
    // Use the first segment as the category
    const category = segments[0];
    // Use the last segment as the subcategory name
    const subcategory = segments[segments.length - 1];
    
    if (!category || !subcategory) return acc;

    // Convert URL format to display format
    const displayCategory = category
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    const displaySubcategory = subcategory
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    if (!acc[displayCategory]) {
      acc[displayCategory] = [];
    }
    
    // Only add if not already present
    if (!acc[displayCategory].some(item => item.path === path)) {
      acc[displayCategory].push({
        path,
        name: displaySubcategory
      });
    }

    return acc;
  }, {});


  return (
    <SEOLayout pageTitle="Huglo Comparison App - Compare your electricity and save">
      <LandingHeader />
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="max-w-3xl mx-auto mb-12 text-center">
          <h1 className="text-3xl font-bold mb-6">Electricity comparison made simple</h1>
          
          <div className="prose prose-lg mx-auto">
            <p className="mb-4">
              Wondering, <em>&ldquo;Am I really getting the best electricity deal?&rdquo;</em> You&apos;re not alone. 
              With over <strong>21,000 electricity plans</strong> on the market, finding the right one can be overwhelming.
            </p>

            <p className="mb-4">
              That&apos;s where Huglo Comparison App comes in to make it easy.
            </p>

            <p className="mb-4">
              As your trusted energy partner, we&apos;ve done the heavy lifting by identifying the best electricity rates available. 
              We&apos;ve meticulously compared every electricity retailer, delivering the most cost-effective plan tailored to your needs.
            </p>

            <p className="mb-4">
              Our promise? <strong>Transparency you can trust.</strong> While some retailers pay referral fees to appear on our platform, 
              this never influences our rankings. Your results are purely based on what&apos;s cheapest for you.
            </p>

            <p className="mb-8">
              Stay confident with the <strong>most accurate and up-to-date deals</strong>, refreshed nightly to ensure 
              you&apos;re always viewing the latest electricity  <a href="https://www.producthunt.com/products/huglo-comparison-app" rel="nofollow">plans.</a>
            </p>
          </div>
        </div>

        <h2 className="text-3xl mb-6 mt-6 text-center">Categories</h2>
        <div className="grid grid-cols-2 lg:grid-cols-4">
          {Object.entries(groupedPages).map(([category, items]) => (
            <div key={category} className="mb-8 text-center">
              <p className="text-base font-bold text-gray-900">{category}</p>
              <ul className="mt-4 space-y-2">
                {items.map(({ path, name }) => (
                  <li key={path}>
                    <Link
                      to={path}
                      className="text-sm font-medium text-gray-700 hover:text-gray-900"
                    >
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </SEOLayout>
  );
};

export default Categories;
    