import React from 'react';
import { useMutation } from "@apollo/client";
import { SELECTED_AD_BANNER } from "../../apollo/queries";
import LandingHeader from './Header';
// @ts-ignore
import BackgroundPattern from '../../assets/images/background-pattern.png';
// @ts-ignore
import iphoneHuglo from '../../assets/images/iphone-huglo.png';

import { SelectedAdBanner, SelectedAdBannerVariables } from "../../apollo/__generated__/SelectedAdBanner";
import { getHasMemberId } from "../../utils/localStorage/localStorageFunctions";

const Hero = () => {
    const [selectedAdBanner] = useMutation<
        SelectedAdBanner,
        SelectedAdBannerVariables
    >(SELECTED_AD_BANNER);

    const handleQuoteClick = async (e: React.MouseEvent) => {
        e.preventDefault();
        const memberId = getHasMemberId() || '';
        const encodedMemberId = memberId ? window.btoa(memberId) : '';
        const encodedAdBannerId = window.btoa('AdBanner:2');
        
        try {
            await selectedAdBanner({
                variables: {
                    memberId: encodedMemberId,
                    selectedAdBannerId: encodedAdBannerId,
                },
            });
            window.open('https://www.huglo.com.au/solar?ref=comparisonapp', '_blank');
        } catch {
            window.open('https://www.huglo.com.au/solar?ref=comparisonapp', '_blank');
        }
    };

    return (
        <div className="bg-gray-50">
            <LandingHeader />

            <section className="relative pt-0 pb-1 md:pb-12 md:pt-0 lg:pb-40 lg:pt-12">
                <div className="absolute bottom-0 right-0">
                    <img className="w-full h-auto origin-bottom-right transform scale-150 lg:w-auto lg:mx-auto lg:object-cover lg:scale-75" src={BackgroundPattern} alt="" />
                </div>

                <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 gap-y-4 lg:items-center lg:grid-cols-2 xl:grid-cols-2">
                        <div className="text-center xl:col-span-1 lg:text-left md:px-16 lg:px-0 xl:pr-20">
                            <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:text-6xl lg:leading-tight font-pj">Compare your electricity and save.</h1>
                            
                            <p className="mt-2 text-lg text-gray-600 sm:mt-6 font-inter">Our clients who check every six months for 2 minutes save up to $400 a year.</p>

                            <div className="flex flex-col mt-8 sm:mt-10">
                                <a
                                href="/compare"
                                title="Huglo Comparison App"
                                className="inline-flex justify-center px-6 py-3 text-base font-bold text-black bg-customOrange border border-transparent rounded transition-all duration-200 hover:bg-black hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-customOrange w-full sm:w-auto"
                                role="button"
                                >
                                COMPARE NOW
                                </a>
                                <div className="mt-4">
                                <p className="text-sm text-gray-600">
                                    Save an additional <strong>58%+</strong> by adding solar to your home. 
                                    <br />
                                    <a 
                                    href="https://www.huglo.com.au/solar?ref=comparisonapp" 
                                    className="inline-flex items-center justify-center w-full sm:w-auto mt-1 text-sm font-bold hover:underline text-center sm:text-left"
                                    onClick={handleQuoteClick}
                                    target="_blank"
                                    rel="noreferrer"
                                    >
                                    Get a Free Quote
                                    </a>
                                </p>
                                </div>
                            </div>

                        </div>
                        <div className="xl:col-span-1 flex flex-col items-center justify-center">
                            <div className="w-full max-w-xs sm:max-w-sm aspect-[4/3]">
                                <img src={iphoneHuglo} alt="iphone-huglo" className="w-full h-full object-contain" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Hero;
